import axios from "axios";
import { getSubRoute } from '../library/utils/yoy-transformer';
import tempData from "./../pages/inputs/temp-data/getObjectives.json";
// let subRout  = getSubRoute();
let subRout = 'gdc';

export const getObjectives = () =>
  new Promise((resolve) => {
    const mock = process.env.REACT_APP_MOCK;
    let fetchUrl = mock
      ? `${process.env.REACT_APP_S3_BUCKET}/${subRout}/custom_api_configs/getObjectives.json`
      : `${process.env.REACT_APP_MICROSERVICE_HOST}/diagnostic/GetObjectives`;
    axios({
      method:  "GET",
      url: fetchUrl
     
    }).then((response) => {
      response = tempData;
      const objectives = {};
      const responseData = response//mock ? response.data : JSON.parse(response.data);
      const responseArray = Object.keys(responseData).map(
        (key) => responseData[key]
      );
      const unique_level_ids = [
        ...new Set(responseArray.map((item) => item["level_table_id"])),
      ];
      const unique_constraint_ids = [
        ...new Set(responseArray.map((item) => item["constraint_id"])),
      ];
      const unique_levels = unique_level_ids.map((level_id) => {
        const itemWithSameLevel = responseArray.filter(
          (r) => r["level_table_id"] == level_id
        )[0];
        return {
          level_type: itemWithSameLevel["level_type_name"],
          level_type_id: itemWithSameLevel["level_type_id"],
          level_name: itemWithSameLevel["level_name"],
          level_name_id: level_id,
        };
      });
      const unique_constraints = unique_constraint_ids.map((constraint_id) => {
        const itemWithSameConstraint = responseArray.filter(
          (r) => r["constraint_id"] == constraint_id
        )[0];
        return {
          constraint_id: constraint_id,
          constraint_name: itemWithSameConstraint["constraint_name"],
          constraint_lhs: itemWithSameConstraint["left_side_cnstrlist"],
          constraint_operator: itemWithSameConstraint["operator"],
          constraint_userinput: itemWithSameConstraint["right_side_cnstrlist"],
        };
      });
      responseArray.map((objectData) => {
        let objectiveRef = objectives[objectData["obj_id"]];
        if (!objectiveRef) {
          objectives[objectData["obj_name"]] = {
            is_active: objectData["is_active"],
            obj_id : objectData["obj_id"],
            objective_name: objectData["obj_name"],
            classification_id: objectData["classification_id"],
            primary_objective: {
              function: {
                function_name: objectData["obj_p_func_name"],
                function_id: objectData["obj_p_func_id"],
              },
              metric: {
                metric_name: objectData["obj_p_metric_name"],
                metric_id: objectData["obj_p_metric_id"],
              },
            },
            secondary_objective: {
              function: {
                function_name: objectData["obj_s_func_name"],
                function_id: objectData["obj_s_func_id"],
              },
              metric: {
                metric_name: objectData["obj_s_metric_name"],
                metric_id: objectData["obj_s_metric_id"],
              },
            },
            level_details: {
              level_operator: objectData["operator_id"],
              levels: [],
            },
            constraints: [],
          };
          objectiveRef = objectives[objectData["obj_name"]];
        }

        if (
          objectiveRef.level_details.levels &&
          objectiveRef.level_details.levels.filter(
            (i) => i["level_name_id"] == objectData["level_table_id"]
          ).length == 0
        ) {
          objectiveRef.level_details.levels.push(
            unique_levels.filter(
              (level) => level["level_name_id"] === objectData["level_table_id"]
            )[0]
          );
        }
        if (
          objectiveRef.constraints &&
          objectiveRef.constraints.filter(
            (i) => i["constraint_id"] == objectData["constraint_id"]
          ).length == 0
        ) {
          objectiveRef.constraints.push(
            unique_constraints.filter(
              (level) => level["constraint_id"] === objectData["constraint_id"]
            )[0]
          );
        }
      });
      console.log(objectives, "objs");
      resolve(objectives);
    });
  });

export const getCorrelationMatrix = (objective_name, variables_to_filter) =>
  new Promise((resolve) => {
    const isMock = process.env.REACT_APP_MOCK;
    if (isMock) {
      axios
        .get(`${process.env.REACT_APP_S3_BUCKET}/${subRout}/viz_configs/sample_data/diagnostic/correlationMatrix.json`)
        .then((response) => {
          resolve(response.data);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_MICROSERVICE_HOST}/diagnostic/GetCorrelationData`,
          {
            objective_name,
            variables_to_filter,
          },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve({
            fields: [],
            data: [],
          });
        });
    }
  });

export const getBivariateData = (
  objective_name,
  first_variable_to_select,
  second_variable
) =>
  new Promise((resolve) => {
    const isMock = process.env.REACT_APP_MOCK;
    if (isMock) {
      axios.get(`${process.env.REACT_APP_S3_BUCKET}/${subRout}/viz_configs/sample_data/diagnostic/bivariate.json`).then((response) => {
        resolve(response.data);
      });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_MICROSERVICE_HOST}/viz/GetSingleDataSetBasedOnConfig`,
          {
            config: {
              cassandra: {
                select: {
                  columns: [
                    {
                      name: first_variable_to_select,
                      newName: first_variable_to_select,
                      allowedValues: [],
                      columnType: "2",
                    },
                    {
                      name: second_variable,
                      newName: second_variable,
                      allowedValues: [],
                      columnType: "2",
                    },
                  ],
                  allowFiltering: false,
                  selectAllColumn: false,
                },
                groupby: {
                  columns: [],
                  keys: [],
                },
                upsert: {
                  columns: [],
                },
                keySpace: "objective_data_summaries",
                tableName: `${objective_name}_sampled_data`,
                delete: {
                  columns: [],
                },
                streamResult: false,
              },
              nodeSequence: [],
              identifier: "sample",
            },
          },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve({
            fields: [],
            data: [],
          });
        });
    }
  });

export const getLevelData = (classificationid) =>
  new Promise((resolve) => {
    if (process.env.REACT_APP_MOCK) {
      axios
        .get(`${process.env.REACT_APP_S3_BUCKET}/${subRout}/viz_configs/sample_data/diagnostic/getLevels.json`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve({});
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_MICROSERVICE_HOST}/diagnostic/GetLevels`,
          {
            classificationid,
          },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        )
        .then((response) => {
          resolve(JSON.parse(response.data));
        })
        .catch(() => {
          resolve({});
        });
    }
  });

export const getVariableSummary = (
  cancelToken,
  objective_name,
  searchText,

  { pageSize, pageNumber },
  conversionRequired
) =>
  new Promise((resolve) => {
    console.log("conversionRequired", conversionRequired);
    const mock = process.env.REACT_APP_MOCK;
    const fetchUrl = mock
      ? `${process.env.REACT_APP_S3_BUCKET}/${subRout}/viz_configs/sample_data/diagnostic/${objective_name}/getVariableSummary.json`
      : `${process.env.REACT_APP_MICROSERVICE_HOST}/diagnostic/GetUnivariateData`;
    setTimeout(
      () => {
        axios(
          {
            method: !mock ? "POST" : "GET",
            url: fetchUrl
           
          },
          { cancelToken }
        )
          .then((response) => {
            const { data } = response;
            let output = data.data.map((el) => {
              let element = {};
              el.forEach((fieldValue, fieldIndex) => {
                element[data.fields[fieldIndex]] = fieldValue;
              });
              return element;
            });
            output.sort((a, b) =>
              parseFloat(a["variable_importance"]) >
              parseFloat(b["variable_importance"])
                ? -1
                : 1
            );

            if (searchText) {
              if (conversionRequired) {
                output = output.filter((o) => {
                  return Boolean(
                    (o["variable_display_name"] || "")
                      .toLowerCase()
                      .split("_")
                      .filter((v) => v)
                      .join(" - ")
                      .indexOf(searchText.toLowerCase()) > -1 ||
                      o["variable_name"]
                        .toLowerCase()
                        .split("_")
                        .filter((v) => v)
                        .join(" - ")
                        .split(searchText.toLowerCase()).length > 1
                  );
                });
              } else {
                output = output.filter((o) => {
                  return Boolean(
                    (o["variable_display_name"] || "").indexOf(
                      searchText.toLowerCase()
                    ) > -1 ||
                      o["variable_name"].split(searchText.toLowerCase())
                        .length > 1
                  );
                });
              }
            }
            if (mock) {
              if (pageNumber > 3) {
                resolve({
                  fields: data.fields,
                  data: [],
                });
              } else {
                resolve({
                  fields: data.fields,
                  data: output.filter((d) => {
                    return (
                      d["variable_display_name"]
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) !== -1
                    );
                  }),
                });
              }
            } else {
              resolve({
                fields: data.fields,
                data: output,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            resolve({
              fields: [],
              data: [],
            });
          });
      },
      mock ? 500 : 10
    );
  });

export const getObjectivesLoadData = () =>
  new Promise((resolve) => {
    let urls = [
      { url: "diagnostic/GetObjFunctions", method: "POST", data: {} },
      { url: "diagnostic/GetConstraints", method: "POST", data: {} },
      { url: "diagnostic/GetObjectiveMetrics", method: "POST", data: {} },
      { url: "classification/GetAllVersions", method: "GET" },
    ];
    if (process.env.REACT_APP_MOCK) {
      urls = [
        {
          url: "sample_data/diagnostic/GetObjFunctions.json",
          method: "GET",
        },
        { url: "sample_data/diagnostic/getConstraints.json", method: "GET" },
        {
          url: "sample_data/diagnostic/getObjectiveMetrics.json",
          method: "GET",
        },
        {
          url: "sample_data/classification/GetAllVersions.json",
          method: "GET",
        },
      ];
    }
    Promise.all(
      urls.map((url) =>
        axios({
          method: url.method,
          url: `${
            process.env.REACT_APP_MOCK
              ? `${process.env.REACT_APP_S3_BUCKET}/${subRout}/viz_configs/`
              : process.env.REACT_APP_MICROSERVICE_HOST
          }${url.url}`,
         
        })
      )
    )
      .then((baseData) => {
        resolve(
          baseData.map((d) => {
            if (typeof d.data === "string") return JSON.parse(d.data);
            return d.data;
          })
        );
      })
      .catch((err) => {
        console.log(err);
        resolve([]);
      });
  });

export const upsertObjective = (objective) =>
  new Promise((resolve) => {
    if (process.env.REACT_APP_MOCK) {
      axios
        .get(`${process.env.REACT_APP_S3_BUCKET}/${subRout}/viz_configs/sample_data/diagnostic/getLevels.json`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve({});
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_MICROSERVICE_HOST}/diagnostic/UpsertObjective`,
          {
            input_json: JSON.stringify(objective),
            update_obj_id: objective.id,
          },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        )
        .then((response) => {
          resolve(JSON.parse(response.data));
        })
        .catch(() => {
          resolve({});
        });
    }
  });

export const toggleObjective = (objectiveId, isactivestatus) =>
  new Promise((resolve) => {
    if (process.env.REACT_APP_MOCK) {
      axios
        .get(`${process.env.REACT_APP_S3_BUCKET}/${subRout}/viz_configs/sample_data/diagnostic/getLevels.json`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve({});
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_MICROSERVICE_HOST}/diagnostic/ActivateDeactivateObjective`,
          {
            objectiveId,
            isactivestatus,
          },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        )
        .then((response) => {
          resolve(JSON.parse(response.data));
        })
        .catch(() => {
          resolve({});
        });
    }
  });

export const publishObjective = () =>
  new Promise((resolve) => {
    if (process.env.REACT_APP_MOCK) {
      axios
        .get(`./sample_data/diagnostic/getLevels.json`, {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve({});
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_MICROSERVICE_HOST}/pipeline/PublishObjectives`,
          {
            bMarkactiveNRun: true,
          },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        )
        .then((response) => {
          resolve(JSON.parse(response.data));
        })
        .catch(() => {
          resolve({});
        });
    }
  });
