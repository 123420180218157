/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import { WaveLoading } from "styled-spinkit";
import { getS3Data } from "@pai/pages/transformer";

const withObjective = (Component) => (props) => {
  const recommendationtypes = ["Acquire", "Engage", "Grow"];
  const defaultObjective = { id: "all_recommendation", name: "ALL Recommendation" };

  // const [objectiveFilteredList, setObjectiveFilteredList] = useState([]);
  const [objectiveListAll, setObjectiveListAll] = useState([]);
  const [objectiveListCascaderOptions, setObjectiveListCascaderOptions] = useState([]);
  const [objective, setObjective] = useState(null);
  const [recommendationType, setRecommendationType] = useState(null);
  const [loading, setLoading] = useState(true);
  // console.log('aaja')
  const objectiveChangedHandler = () => {
    // setLoading(true);
    let selectedObjective = window.localStorage.getItem(
      "p.ai_objective_selected"
    );
    // console.log("session objective--------------->", selectedObjective);
    if (selectedObjective) {
      selectedObjective = JSON.parse(selectedObjective);
      setObjective(selectedObjective);
      // setLoading(false);
    }
  };

  const recommendationTypeChangedHandler = () => {
    // objectiveChangedHandler();
    // setLoading(true);
    let selectedRecommendationType = JSON.parse(window.localStorage.getItem("p.ai_recommendationType_selected"));

    // console.log("session objective--------------->", selectedObjective); 
    if (!selectedRecommendationType) {
      selectedRecommendationType = recommendationtypes[0];
      window.localStorage.setItem(
        "p.ai_recommendationType_selected",
        JSON.stringify(selectedRecommendationType)
      );

    }
    setRecommendationType(selectedRecommendationType);
    // setLoading(false);
  };

  // const getFilteredObjectiveList = () => {
  //   let objectiveFilteredList = window.localStorage.getItem(
  //     "p.ai_objectiveList"
  //   );
  //   if (objectiveFilteredList) {
  //     objectiveFilteredList = JSON.parse(objectiveFilteredList);
  //     setObjectiveFilteredList(objectiveFilteredList);
  //   }
  // }

  const getAllObjectiveList = async () => {
    console.log('p.ai_objectiveList_all', window.localStorage.getItem("p.ai_objectiveList_all") != undefined)
    let objectiveListAll = null // window.localStorage.getItem("p.ai_objectiveList_all") != undefined && JSON.parse(window.localStorage.getItem("p.ai_objectiveList_all"));
    if (!objectiveListAll) {
      console.log('else getAllObjectiveList');
      const objectivesListData = await getS3Data("RecommendationList.json");
      objectiveListAll = await objectivesListData?.['tests']?.filter(
        (x) => x.dagStatus === 3 || x.dagStatus === 'completed'
      );
      window.localStorage.setItem(
        "p.ai_objectiveList_all",
        JSON.stringify(objectiveListAll)
      );
      console.log('else getAllObjectiveList', objectiveListAll);

    }
    setObjectiveListAll(objectiveListAll);

    // Logic to generate cascade options
    const options = [];
    if (objectiveListAll && objectiveListAll.length > 0) {
      recommendationtypes.map(recItem => {
        let optionItem = {
          label: recItem,
          value: recItem,
          children: [
            {
              label: defaultObjective.name,
              value: defaultObjective.id,
              objective: defaultObjective
            }
          ]
        };
        objectiveListAll
          .filter(obj => obj.objective == recItem)
          .map(obj => {
            optionItem.children.push(
              {
                label: obj.name,
                value: obj.id,
                objective: obj
              }
            )
          });
        options.push(optionItem);
      });
    }
    // 
    setObjectiveListCascaderOptions(options);

  }

  const loadData = async () => {
    setLoading(true);

    // getFilteredObjectiveList();
    await getAllObjectiveList();
    objectiveChangedHandler();
    recommendationTypeChangedHandler();

    setLoading(false);

  }
  useEffect(() => {
    // console.log("uef inside withobj");

    loadData()

    // window.addEventListener("ai_objectiveList", getFilteredObjectiveList, false);
    window.addEventListener("ai_objectiveList_all", getAllObjectiveList, false);
    window.addEventListener("objectiveChanged", objectiveChangedHandler, false);
    window.addEventListener("recomendationType", recommendationTypeChangedHandler, false);

    return () => {
      // window.removeEventListener("ai_objectiveList", getFilteredObjectiveList);
      window.removeEventListener("ai_objectiveList_all", getAllObjectiveList);
      window.removeEventListener("objectiveChanged", objectiveChangedHandler);
      window.removeEventListener("recomendationType", recommendationTypeChangedHandler);
    };
  }, []);

  // method to get default cascader option on page load based on recommendationType and objective
  const getDefaultCascaderOption = (recommendationType, objective) => {
    console.log("getDefaultCascaderOption", recommendationType, objective);

    let defaultCascaderOption = [recommendationType]
    if (objective) {
      defaultCascaderOption.push(objective.id)
    }
    else {
      defaultCascaderOption.push(defaultObjective.id)
    }
    return defaultCascaderOption;
  }

  // method to update cascader state value 
  const onCascaderOptionChange = (setSelectedCascaderOption, objective, option, selectedOptions,) => {
    let selectedObj = objective;
    if (selectedOptions && selectedOptions.length > 1) {
      selectedObj = selectedOptions[1].objective.id === 'all_recommendation' ? null : selectedOptions[1].objective;
    }
    window.localStorage.setItem(
      "p.ai_recommendationType_selected",
      JSON.stringify(option[0])
    );
    window.localStorage.setItem(
      "p.ai_objective_selected",
      JSON.stringify(selectedObj)
    );
    setSelectedCascaderOption(option);
  }

  // method for triggering cascader select change
  const onCascaderSelect = (setSelectedCascaderOption, objective, value, selectedOptions) => {
    console.log('onCascaderSelect', value, selectedOptions);
    let option = value;
    if (value && value.length == 1) {
      // setSelectedCascaderOption
      let optionChild1 = selectedOptions[0].children[0].value
      option.push(optionChild1)
    }
    onCascaderOptionChange(setSelectedCascaderOption, objective, option, selectedOptions);
  };

  if (loading) return <WaveLoading color="#15afd0" />;

  return <Component {...props}
    objective={objective}
    recommendationType={recommendationType}
    // objectiveFilteredList={objectiveFilteredList}
    objectiveListAll={objectiveListAll}
    objectiveListCascaderOptions={objectiveListCascaderOptions}
    getDefaultCascaderOption={getDefaultCascaderOption}
    onCascaderSelect={onCascaderSelect}
    onCascaderOptionChange={onCascaderOptionChange}
  />;
};

export default withObjective;
