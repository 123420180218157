import React, { useState, useEffect } from 'react'
import { VolumeMute } from '@styled-icons/material/VolumeMute'
import {MuteCartWrapper, MuteCartCount} from './styles'
import withObjective from '../../../providers/with-objective'
import { getLocalMutedList } from '../../../services/variables'

const MuteMenu = ({ objective }) => {
    const [mutedFeatures, setMutedFeatures] = useState(null)
    const onMutedFeaturesChange = () => {
        if(objective){
            getLocalMutedList(objective.id).then(setMutedFeatures)
        }
    }
    useEffect(()=>{
        onMutedFeaturesChange()
        window.addEventListener("mutedFeaturesChange", onMutedFeaturesChange, false)
        return () => {
            window.removeEventListener("mutedFeaturesChange", onMutedFeaturesChange)
        }
    },[objective])

    console.log(mutedFeatures)

    return (
        <MuteCartWrapper>
            <VolumeMute fill="#ffffff80" width={24} />
            {mutedFeatures && !!mutedFeatures.length && 
                <MuteCartCount>{mutedFeatures.length}</MuteCartCount>
            }
        </MuteCartWrapper>
    )
}

export default withObjective(MuteMenu)