import React from "react";
import { Typography, Panel } from "@pai/library/base";
import { Grid, GridItem } from "./../../library/containers/grid";
import { Row, Col } from '@pai-ui/core';
import { UserCircle } from '@styled-icons/fa-regular/UserCircle'; 
import { ArrowGrowth } from '@styled-icons/fluentui-system-filled/ArrowGrowth';
import { UserSearch } from '@styled-icons/remix-line/UserSearch';


const Section = ({ children, dark, style }) => (
  <Panel
    style={{
      ...style,
      minHeight: "calc(111vh - 55px)",
      backgroundColor: dark ? "rgba(0,0,0,0.5)" : "transparent",
      marginLeft: "-20px",
      marginRight: "-20px",
      padding: "75px 0",
    }}
  >
    {children}
  </Panel>
);

const Container = ({ children, large, style }) => (
  <Panel
    style={{
      ...style,
      width: large ? "auto" : 1390,
      marginLeft: "auto",
      marginRight: "auto",
    }}
  >
    {children}
  </Panel>
);

const LandingPage = () => {
 return (<React.Fragment>
  <Section
    style={{
      display: 'flex', flexDirection:'column',alignItems: 'flex-start', justifyContent: 'flex-start',marginLeft:'0px',
      backgroundImage: "url(./img/landing/contact.jpg)",
      backgroundSize: "contain",
      backgroundPosition: "center right",
      backgroundRepeat: "no-repeat",
    }}
  >
     <Typography.Heading2 style={{ marginTop:'16vh', paddingLeft:'3%', paddingRight:'40px', fontSize:'38px', letterSpacing:'1.8px', fontWeight:600,  lineHeight: 1.2 }}>
      Reinventing<br/> B2B Demand Generation
      </Typography.Heading2>
      <Typography.Heading3 style={{ paddingLeft: '3%', paddingRight:'40px', fontWeight:'400', fontFamily: 'SF Display', fontSize:'18px',  marginTop:'3vh', letterSpacing:'1px', lineHeight: '30px' }}>
                 Artificial Intelligence (AI) can enrich B2B consumer&apos;s Journey<br/> at every step while optimizing and personalizing marketing<br/> and sales experiences.
                </Typography.Heading3>
  </Section>
  <div
        style={{ height: 5, background: "#00000075", margin: "0px -20px" }}
  ></div>
  <Section>
    <Container style={{ marginTop:'12vh'}}>
      <Row>
        <Col span={3}></Col>
        <Col span={18}>
            <Typography.Heading3 colorStyle="primary" style={{  textAlign:'center', letterSpacing:'1px', textTransform:'uppercase' }}>
              Product Features
            </Typography.Heading3>
            <Typography.Heading2 style={{ textAlign:'center',  fontFamily: 'SF Display', fontSize:'42px', letterSpacing:'1.2px', fontWeight:600, margin:'28px' }}>
              Orchestrate the ultimate customer experience
            </Typography.Heading2>
            <Typography.Heading3 style={{ textAlign:'center', fontWeight:'400',  fontFamily: 'SF Display', paddingRight:'22px', fontSize:'18px',  marginTop:'5vh', letterSpacing:'1px', lineHeight: '30px' }}>
              Rapidly deploy the centralized demand engine and leverage intelligent targeting and multi-channel activation to orchestrate omnichannel buying experience,
              accelerate pipeline, and drive revenue.
            </Typography.Heading3>
        </Col>
        <Col span={3}></Col>
      </Row>
      <Row>
        <Col span={2}></Col>
        <Col span={5} style={{ padding:'15px' }}>
          <div align="center">
            <ArrowGrowth
              size="48"
              style={{ color:'#15afd0', opacity: 0.75, marginTop: 90, display: "block" }}
            />
          </div>
          <div style={{  textAlign:'center', color:'#fff', marginTop:'25px', fontSize:'16px', fontFamily: 'SF Display' }}>
            E2E Customer journey orchestration and optimization
          </div>
        </Col>
        <Col span={5}  style={{ padding:'15px' }}>
          <div align="center">
            <UserSearch
              size="48"
              style={{ color:'#15afd0', opacity: 0.75, marginTop: 90, display: "block" }}
            />
          </div>
          <div style={{ textAlign:'center', color:'#fff', marginTop:'25px', fontSize:'16px', fontFamily: 'SF Display'  }}>
            Always-on intelligent account segmentation and prioritization
          </div>
        </Col>
        <Col span={5}  style={{ padding:'15px' }}>
          <div align="center">
            <UserCircle
              size="48"
              style={{ color:'#15afd0', opacity: 0.75, marginTop: 90, display: "block" }}
            />
          </div>
          <div style={{  textAlign:'center', color:'#fff', marginTop:'25px', fontSize:'16px' }}>
            Engage right audience, right content, right time
          </div>
        </Col>
        {/*<Col span={5} style={{ padding:'15px'}}>
          <div align="center">
            <img
              src="./img/landing/artificial.png"
              width="50"
              style={{ opacity: 0.75, marginTop: 90, display: "block" }}
            />
          </div>
          <div style={{  textAlign:'center', color:'#fff', marginTop:'25px', fontSize:'16px' }}>
            Monitor performance of every action, to learn, improvise & adapt
          </div>
        </Col>*/}
        <Col span={2}></Col>
      </Row>
    </Container>
  </Section>
</React.Fragment>);
};

export default LandingPage;