import axios from "axios";

const getSpread = (variable, variable_samples) => {
  if (variable["type"] === "string") {
    const uniqueValues = [...new Set(variable_samples)].sort((a, b) =>
      a > b ? 1 : -1
    );
    return uniqueValues
      .map((v) => ({
        category: v,
        count: variable_samples.filter((s) => s === v).length,
      }))
      .filter((v) => v.count);
  }
  if (variable["type"] === "double" || variable["type"] === "int") {
    const minValue = Math.min(...variable_samples);
    const maxValue = Math.max(...variable_samples);
    const binSize = 100;
    const categorySize = (maxValue - minValue) / binSize;
    return new Array(binSize)
      .fill(0)
      .map((m, i) => {
        const sectionMin = minValue + i * categorySize;
        const sectionMax = sectionMin + categorySize;
        return {
          category:
            i === 0 ? `${sectionMin.toFixed(2)}` : sectionMax.toFixed(2),
          count: variable_samples.filter(
            (v) => v >= sectionMin && v < sectionMax
          ).length,
        };
      })
      .filter((v) => v.count);
  }
  return [];
};

export const getVariablePlot = (objectiveId, variable) =>
  new Promise((resolve) => {
    const mock = process.env.REACT_APP_MOCK;
    const fetchUrl = mock
      ? `./sample_data/diagnostic/${objectiveId}/getVariablePlot.json`
      : `${process.env.REACT_APP_MICROSERVICE_HOST}/viz/GetSingleDataSetBasedOnConfig`;

    const data = {
      config: {
        cassandra: {
          select: {
            columns: [
              {
                name: variable.variable_name,
                newName: variable.variable_name,
                allowedValues: [],
                columnType: "2",
              },
            ],
            allowFiltering: false,
            selectAllColumn: false,
          },
          groupby: {
            columns: [],
            keys: [],
          },
          upsert: {
            columns: [],
          },
          keySpace: "objective_data_summaries",
          tableName: `${objectiveId}_sampled_data`,
          delete: {
            columns: [],
          },
          streamResult: false,
        },
        nodeSequence: [],
        identifier: "sample",
      },
    };

    setTimeout(
      () => {
        axios({
          method: !mock ? "POST" : "GET",
          url: fetchUrl,
          data: !mock ? data : undefined,
          headers: { "x-api-key": process.env.REACT_APP_API_KEY },
        })
          .then((response) => {
            let { data } = response;
            if (mock) {
              data = {
                data: data.map((d) => d[variable.variable_name]),
              };
            }
            resolve(getSpread(variable, [].concat.apply([], data.data)));
          })
          .catch(() => {
            resolve([]);
          });
      },
      mock ? 500 : 10
    );
  });

export const getVariableSummary = (obj, search) =>
  new Promise((resolve) => {
    axios.get(`./sample_data/${obj} Variables.json`).then((response) => {
      const { data } = response;
      const output = data.data.map((el) => {
        let element = {};
        el.forEach((fieldValue, fieldIndex) => {
          element[data.fields[fieldIndex]] = fieldValue;
        });
        return element;
      });
      resolve({
        fields: data.fields,
        data: output.filter((d) => {
          return d["variable_name"].indexOf(search) !== -1;
        }),
      });
    });
  });

const triggerMuteChangeEvent = () => {
  var event = new window.Event("mutedFeaturesChange");
  window.dispatchEvent(event);
};

export const addToMuteList = (obj, variableName) =>
  new Promise((resolve) => {
    const mutedLocalFeatures = window.localStorage.getItem(
      `${obj}_feature_muting`
    );
    let mutedList = mutedLocalFeatures ? mutedLocalFeatures.split(",") : [];
    mutedList.push(variableName);
    mutedList = [...new Set(mutedList)];
    window.localStorage.setItem(`${obj}_feature_muting`, mutedList.join(","));
    triggerMuteChangeEvent();
    resolve(true);
  });

export const getLocalMutedList = (obj) =>
  new Promise((resolve) => {
    const mutedLocalFeatures = window.localStorage.getItem(
      `${obj}_feature_muting`
    );
    let mutedList = mutedLocalFeatures ? mutedLocalFeatures.split(",") : [];
    resolve(mutedList);
  });

export const removeFromMuteList = (obj, variableName) =>
  new Promise((resolve) => {
    const mutedLocalFeatures = window.localStorage.getItem(
      `${obj}_feature_muting`
    );
    let mutedList = mutedLocalFeatures ? mutedLocalFeatures.split(",") : [];
    const foundIndex = mutedList.indexOf(variableName);
    mutedList.splice(foundIndex, 1);
    mutedList = [...new Set(mutedList)];
    window.localStorage.setItem(`${obj}_feature_muting`, mutedList.join(","));
    triggerMuteChangeEvent();
    resolve(true);
  });
