//icons
// import { Monitor } from "@styled-icons/feather/Monitor";
// import { MakeGroup } from "@styled-icons/icomoon/MakeGroup";
import { Input } from "@styled-icons/material/Input";
import { TestTube } from "@styled-icons/boxicons-regular/TestTube";
// import { UserLock } from "@styled-icons/fa-solid/UserLock";
import { Coupon2 } from "@styled-icons/remix-line/Coupon2";
import * as Dash from "@styled-icons/remix-line/Dashboard";
import { Link } from "@styled-icons/boxicons-regular/Link";
import { Database } from "@styled-icons/feather/Database";
// import { Station } from "@styled-icons/boxicons-regular/Station";
import { Users } from '@styled-icons/fa-solid/Users';
import { BookOpen } from "@styled-icons/boxicons-regular/BookOpen";

export const LandingConfig = {
  groups: [
    { 
      name: "Quick Links",
      items: [
        {
          text: "Product",
          icon: Dash.Dashboard,
          linked: "/product",
          defaultRoute: "/",
          subMenu: [],
        },
       /* {
          text: "Research",
          icon: BookOpen,
          linked: "/research",
          defaultRoute: "/",
          subMenu: [],
        } */
      ],
    },
  ],
};

export const MenuConfig = {
  groups: [
    {
      name: "Executive",
      items: [
        {
          text: "At a Glance",
          icon: Dash.Dashboard,
          linked: "/dashboard",
          defaultRoute: "/opportunities",
          subMenu: [
            {
              name: "Opportunities", //Tab name
              linked: "/opportunities", //link that will be shown in the URL
              key: "AAGOpportunity", //Componenet Name
            },
            {
              name: "Result", //Tab name
              linked: "/result", //link that will be shown in the URL
              key: "AAGResult", //Componenet Name
            },
            // {
            //   name: "Summary",
            //   linked: "/results_summary",
            //   key: "MockPage",
            // },
            // {
            //   name: "Opportunities", //Tab name
            //   linked: "/opportunities", //link that will be shown in the URL
            //   key: "AAGOpportunity", //Componenet Name
            // },
            // {
            //   name: "Bundles Deep Dive ", //Tab name
            //   linked: "/bundles", //link that will be shown in the URL
            //   key: "BundlesDeepDive", //Component Name
            // },
            // // { name: "Cohorts", linked: "/cohorts", key: "PersonasAtAGlance" },
          ],
        },
      ],
    },

    {
      name: "Data Science",
      items: [   
        {
          text: "Business Inputs & Diagnosis",
          icon: Input,
          linked: "/businessinputs",
          defaultRoute: "/inputs",
          subMenu: [
           /* {
              name: "Objectives",
              linked: "/objective",
              key: "Objectives",
            }, */
            {
              name: "Inputs",
              linked: "/inputs",
              key: "Inputs",
              type: "Component",
              componentKey: "ObjectiveMenu",
            },
            {
              name: "FeatureEngineering",
              linked: "/FeatureEngineering",
              key: "FeatureEngineering"
            },
            {
              name: "Univariate Analysis",
              linked: "/Univariate-Analysis",
              key: "Univariate"
            },
            {
              name: "Bivariate & Correlation",
              linked: "/bivariate-correlation",
              key: "BivariateCorrelation"
            },
            {
              name: "Hierarchy",
              linked: "/hierarchy",
              key: "Hierarchy",
            },
          ],
          actions: [],
        },
        {
          text: "Audience intelligence",
          icon: Users,
          linked: "/account_lead",
          defaultRoute: "/prioritization",
          subMenu: [
            {
              name: "Prioritization",
              linked: "/prioritization",
              key: "AccountPrioritization",
            },
            {
              name: "Audience Details",
              linked: "/AudienceDetails",
              key: "AudienceDetails",
            },
            {
              name: "Intuit",
              linked: "/intuit",
              key: "Intuit",
            },
            /* {
              name: "AI Model Diagnosis",
              linked: "/AIModelDiagnosis",
              key: "AIModelDiagnosis",
            }, */
            {
              name: "Explore Account",
              linked: "/exploretargetlist",
              key: "ExploreTargetList",
            },
          ],
          actions: [],
        },
        {
          text: "Action Recommendations",
          icon: Coupon2,
          linked: "/recommendation",
          defaultRoute: "/landing",
          subMenu: [
            {
              name: "Recommendation",
              linked: "/landing",
              key: "RecommendationLanding",
              // type: "Component",
              // componentKey: "ObjectiveMenu",
            },
            {
              name: "Summary",
              linked: "/summary",
              key: "RecommendationSummary",
            },
            {
              name: "Performance",
              linked: "/performance",
              key: "RecommendationPerformance",
            },
          //  {
          //    name: "AI Performance",
          //    linked: "/aiperformance",
          //    key: "AIPerformance",
          //  },
            // {
            //   name: "Items & Offers",
            //   linked: "/items-offers",
            //   key: "ItemOfferRecom",
            //   dummy: true,
            // },

            // { name: "Items & Offers", linked: "/items-offers", key:"ItemOffer" },
            //  { name: "Diagnostics", linked: "/diagnostics", key: "Diagnostics" },

          ],
        },

        // {
        //   text: "A/B Testing",
        //   icon: TestTube,
        //   linked: "/testing",
        //   defaultRoute: "/tests",
        //   subMenu: [
        //     { name: "Tests", linked: "/tests", key: "ABTestList", dummy: true },
        //     {
        //       name: "Measure",
        //       linked: "/measure",
        //       key: "AbTestResult",
        //       dummy: true,
        //     },
        //     {
        //       name: "",
        //       linked: "/analyze/:id?",
        //       key: "ABTestAnalyse",
        //       dummy: true,
        //     },
        //     {
        //       name: "", //Tab name
        //       linked: "/build", //link that will be shown in the URL
        //       key: "NewAbTest", //Component Name
        //     },

        //   ],
        // },
        // {
        //   text: "Recommendation",
        //   icon: Coupon2,
        //   linked: "/recommendation",
        //   defaultRoute: "/overview",
        //   subMenu: [
        //     {
        //       name: "Overview",
        //       linked: "/overview",
        //       url: `${legacyHost}/recommendation/summary`,
        //     }
        //   ],
        // },
        // {
        //   text: "A/B Testing",
        //   icon: TestTube,
        //   linked: "/testing",
        //   defaultRoute: "/tests",
        //   subMenu: [
        //     {
        //       name: "Tests",
        //       linked: "/tests",
        //       url: `${legacyHost}/testing/tests`,
        //     },
        //     {
        //       name: "Measure",
        //       linked: "/measure/:id?",
        //       url: `${legacyHost}/testing/measure/:id?`,
        //     },
        //     {
        //       name: "",
        //       linked: "/build/:id?",
        //       url: `${legacyHost}/testing/build/:id?`,
        //     },
        //     {
        //       name: "",
        //       linked: "/analyze/:id?",
        //       url: `${legacyHost}/testing/analyze/:id?`,
        //     },
        //   ],
        // },

      ],
    },
    {
      name: "Performance Measurement",
      items: [
        {
          text: "End-to-End Reporting",
          icon: TestTube,
          linked: "/performance_measurement",
          defaultRoute: "/results_summary",
          subMenu: [
            {
              name: "Summary",
              linked: "/results_summary",
              key: "AAGResultsSummary",
            },
            // {
            //   name: "End-to-End Reporting",
            //   linked: "/reporting",
            //   key: "EndToEndReporting",
            // },
          ],
        },
      ],
    },
    {
      name: "Operationalization",
      items: [
        {
          text: "Data ",
          icon: Database  ,
          linked: "/data",
          defaultRoute: "/import",
          subMenu: [
            {
              name: "Data",
              linked: "/import",
              key: "DataImport",
            },
          ],
          actions: [],
        },
        {
          text: "Integrations",
          icon: Link,
          linked: "/integrations",
          defaultRoute: "/all",
          subMenu: [
            {
              name: "Integrations",
              linked: "/all",
              key: "Integrations",
            },
          ],
          actions: [],
        },
        // {
        //   text: "ApiOut",
        //   icon: Station,
        //   linked: "/api_out",
        //   defaultRoute: "/all",
        //   subMenu: [
        //     {
        //       name: "Api Out",
        //       linked: "/all",
        //       key: "ApiOut",
        //     },
        //   ],
        //   actions: [],
        // }
      ],
    },
    // {
    //   name: "Operationalization",
    //   items: [
    //     {
    //       text: "Integrations",
    //       icon: Link,
    //       linked: "/integrations",
    //       defaultRoute: "/all",
    //       subMenu: [
    //         {
    //           name: "Integrations",
    //           linked: "/all",
    //           url: `${legacyHost}/integrations/all`,
    //         },
    //       ],
    //     },
    //   ],
    // },

    // {
    //   name: "Operationalization",
    //   items: [
    //     {
    //       text: "Integrations",
    //       icon: Link,
    //       linked: "/integrations",
    //       defaultRoute: "/all",
    //       subMenu: [
    //         {
    //           name: "Integrations",
    //           linked: "/all",
    //           key: "Integrations"
    //         },
    //       ],
    //       actions: [],
    //     },
    //   ],
    // },

    // {
    //   name: "Administration",
    //   last: true,
    //   items: [
    //     {
    //       text: "Monitoring",
    //       icon: Monitor,
    //       linked: "/monitoring",
    //       defaultRoute: "/pipelines",
    //       subMenu: [
    //         {
    //           name: "Execution Pipelines",
    //           linked: "/pipelines",
    //           key: "PipelineManagement",
    //         },
    //       ],
    //     },
    //     {
    //       text: "Access Control",
    //       icon: UserLock,
    //       linked: "/accesscontrol",
    //       defaultRoute: "/AccessControl",
    //       subMenu: [
    //         {
    //           name: "Access Control",
    //           linked: "/accesscontrol",
    //           key: "AccessControl",
    //         },
    //       ],
    //     },
    //     // {
    //     //   text: "Pipeline Monitoring",
    //     //   icon: Monitor,
    //     //   linked: "/monitoring",
    //     //   defaultRoute: "/pipelines",
    //     //   subMenu: [
    //     //     {
    //     //       name: "Execution Pipelines",
    //     //       linked: "/pipelines",
    //     //       key: "PipelineManagement",
    //     //     },
    //     //   ],
    //     // },
    //     // {
    //     //   text: "Infrastructure Monitoring",
    //     //   icon: Admin,
    //     //   linked: "/infrastructure",
    //     //   defaultRoute: "/pipeline",
    //     //   subMenu: [{ name: "Pipeline", linked: "/pipeline" }],
    //     // },
    //   ],
    // },
  ],
};

export const FeedbackProps = {
  mailId: "pai.support@zs.com",
  defaultSubj: "Feedback for Personalize.AI",


}