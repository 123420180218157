import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useHistory, Link } from "react-router-dom";
import ConfigurePage from "./components/configure/index";
import { Header, Components, PageWrapper } from "./builder-components";

export default () => {
  const [showHamburgerMenu, setHamburgerMenu] = useState(false);

  const history = useHistory();

  return (
    <PageWrapper showHamburgerMenu={showHamburgerMenu}>
      <Header
        showHamburgerMenu={showHamburgerMenu}
        setHamburgerMenu={setHamburgerMenu}
      />
      <div style={{ width: "100%", maxWidth: "calc(111vw - 67px)", marginBottom:'25px' }}>
        <div className="row">
          <div className="col-12">
            <Components.ProductList2
              removeContainer
              source={`/gdc_demo/product-listing.json`}
              elementSize={4}
            />
          </div>
          </div>
          </div>
    </PageWrapper>
  );
};
