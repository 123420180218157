import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import {
  Container,
  Body,
  Logo,
  LogoName,
  SubMenuItem,
  ScrollContainer,
  Sidebar,
  PageContainer,
  Grid,
  MenuText,
  LogoStatic,
  LogoDynamic,
} from "./styles";
import { MenuConfig } from "./config";
import Menu from "../../components/menu";
import { Menu as Hamburger } from "@styled-icons/boxicons-regular/Menu";
import * as Components from "../../components";
import LandingPage from "./landing-page";
import ResearchPage from "./research-page";
import { Link } from "react-router-dom";
// import Protect from "react-app-protect";
// import "./style_password.css";
import ProductsPage from "./products-page";

const merge = (array1, array2) => {
  if (!array1) {
    return array2 || [];
  }
  if (!array2) {
    return array1 || [];
  }
  return array1.concat(array2);
};

const AuthLayout = ({ children }) => {
  const storageValue = window.localStorage.getItem("pai_collapsed");
  const [collapsed, setCollapsed] = useState(
    !storageValue || storageValue === "true"
  );

  // let password = "Personalize02";
  // var sha512 = require("js-sha512").sha512;
  // let password_crypted = sha512(password);

  useEffect(() => {
    var event = new window.Event("customResize");
    window.dispatchEvent(event);
    window.localStorage.setItem("pai_collapsed", collapsed);
  }, [collapsed]);
  return (
    <div className='test_protect'>
    {/* comment the below protect wrapper to remove auth */}
    {/*<Protect wrapperClass={"test_protect"} sha512={password_crypted}>*/}
     
      <Router basename={process.env.PUBLIC_URL}>
        <Container>
          <Switch>
            <Route path="/">
              <Switch>
                <Route exact path="/"></Route>
                <Route exact path="/research"></Route>
                <Route exact path="/product"></Route>
                <Route exact path="/ai-tour"></Route>
                <Route path="/">
                  <Sidebar collapsed={collapsed}>
                    <Link to="/">
                      <Logo>
                        <LogoName>
                          <LogoStatic>A</LogoStatic>
                          <LogoDynamic>ct</LogoDynamic>
                          <LogoStatic>
                            <small>.</small>
                            <span>AI</span>
                          </LogoStatic>
                        </LogoName>
                      </Logo>
                    </Link>
                    <ScrollContainer>
                      <Menu groups={MenuConfig.groups} linked />
                    </ScrollContainer>
                  </Sidebar>
                </Route>
              </Switch>
              <Switch>
                <Route exact path="/">
                  <Body>
                    <PageContainer noPad>
                      <LandingPage />
                    </PageContainer>
                  </Body>
                </Route>
                <Route exact path="/research">
                  <Body>
                    <PageContainer noPad>
                      <ResearchPage />
                    </PageContainer>
                  </Body>
                </Route>
                <Route exact path="/product">
                  <Body>
                    <PageContainer noPad>
                      <ProductsPage />
                    </PageContainer>
                  </Body>
                </Route>
                {MenuConfig.groups.map((g) =>
                  g.items.map((i) => [
                    <Route exact path={i.linked}>
                      <Redirect to={i.linked + i.defaultRoute} />
                    </Route>,
                    ...merge(i.subMenu, i.actions).map((s) => (
                      <Route path={i.linked + s.linked}>
                        <Body>
                          {true && (
                            <Logo
                              faded
                              style={{ backgroundColor: "rgba(0,0,0,0.25)" }}
                            >
                              <div
                                onClick={() => setCollapsed(!collapsed)}
                                style={{
                                  padding: "0.8rem 0rem",
                                  margin: "0px 0.188rem",
                                  marginBottom: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  opacity: 0.75,
                                }}
                              >
                                <Hamburger
                                  color="#FFF"
                                  style={{ width: 20, height: 20 }}
                                />
                              </div>
                              {i.subMenu &&
                                i.subMenu.map((s) => {
                                  const ActionComponent =
                                    Components[s.componentKey];
                                  if (
                                    s.type === "Component" &&
                                    ActionComponent
                                  ) {
                                    return <ActionComponent />;
                                  }
                                  return (
                                    <SubMenuItem
                                      activeClassName="activeSubMenu"
                                      to={
                                        s.linked
                                          ? i.linked + s.linked
                                          : undefined
                                      }
                                    >
                                      <MenuText>{s.name}</MenuText>
                                    </SubMenuItem>
                                  );
                                })}
                              <div style={{ marginLeft: "auto" }}>
                                {i.actions &&
                                  i.actions.map((s, index) => {
                                    const ActionComponent =
                                      Components[s.componentKey];
                                    if (!s.linked) {
                                      return <ActionComponent />;
                                    }
                                    return (
                                      <SubMenuItem
                                        firstSubGroup={index == 0}
                                        activeClassName="activeSubMenu"
                                        to={
                                          s.linked
                                            ? i.linked + s.linked
                                            : undefined
                                        }
                                      >
                                        {s.type === "Component" &&
                                          ActionComponent && (
                                            <ActionComponent />
                                          )}
                                        {s.type !== "Component" && (
                                          <MenuText>{s.name}</MenuText>
                                        )}
                                      </SubMenuItem>
                                    );
                                  })}
                              </div>
                            </Logo>
                          )}
                          <PageContainer id="page-container">
                            {children && children({ pageName: s.key })}
                          </PageContainer>
                        </Body>
                      </Route>
                    )),
                  ])
                )}
              </Switch>
            </Route>
          </Switch>
        </Container>
      </Router>
      
    {/*</Protect>*/}
    </div>
  );
};

export default AuthLayout;
