import React from 'react';
import { NavLink } from 'react-router-dom';
import { MenuWrapper, MenuItem, MenuText, MenuIcon, MenuGroup, LinkedMenuItem, LinkedMenuItemSubRoute } from './styles'

const Menu = ({ groups, highlighted, onClick, currentKey, linked }) => groups.map(group => [
    <MenuGroup bottom={group.last}>{group.name}</MenuGroup>,
    <MenuWrapper>
        {group.items.map(item => {
            const Icon = item.icon
            const WrapperComponent = linked ? LinkedMenuItem : MenuItem

            return (
                <WrapperComponent
                    highlighted={highlighted}
                    onClick={()=>onClick && onClick(item.key, item)}
                    to={item.linked} //TODO update this line to modify subroute for the left navbar
                    activeClassName="activeMenu"
                    active={!item.linked && item.key === currentKey}
                    >
                    <MenuIcon><Icon color="#FFF" /></MenuIcon>
                    <MenuText>{item.text}</MenuText>
                </WrapperComponent>
            )
        })}
    </MenuWrapper>
])

export default Menu