
import axios from "axios";

export const removeDelimiterAndToCamelCase = (text, delimiter = "_") => {
  return text
    ? text
      .split(delimiter)
      .map(
        (eachText) =>
          eachText.charAt(0).toUpperCase() +
          eachText.slice(1, eachText.length).toLowerCase()
      )
      .join(" ")
    : "";
};

export const getSubRoute = () =>{
  // return window.location.href.split('/')[4];
  return "fnb";
}

export const getS3Data1 = ( json ) =>
new Promise((resolve) => {
  console.log('getS3Data service');
  let subRout = 'gdc';
  console.log('getS3Data service',subRout,json,
  `${process.env.REACT_APP_S3_BUCKET}/${subRout}/custom_api_configs/${json}`); 
  let fetchUrl =  `${process.env.REACT_APP_S3_BUCKET}/${subRout}/custom_api_configs/${json}`  
  // let fetchUrl =  `./sample_data/custom/${json}`  
  
  axios.get(fetchUrl).then((response) => {
      console.log('api data',response.data)
    resolve(response.data);
  });

//   axios({
//     method: "GET" ,
//     url: fetchUrl,
//   })
//     .then((response) => {
//       // console.log("hypothesis ddn from s3", response.data);
//       resolve(response.data);
//     })
//     .catch((err) => {
//       console.log("error in API call", err);
//       resolve({
//         data: [],
//       });
//     });
});



export const getS3Data = (json) =>
  new Promise((resolve) => {
    let subRout = 'gdc';
    console.log('getS3Data service',subRout,json,
    `${process.env.REACT_APP_S3_BUCKET}/${subRout}/custom_api_configs/${json}`);
    let fetchUrl =  `${process.env.REACT_APP_S3_BUCKET}/${subRout}/custom_api_configs/${json}`  
    

    axios({
      method: "GET",
      url: fetchUrl,
      }
    )
      .then((response) => {
        console.log(response.data, "response");
        resolve(response.data);
      })
      .catch((err) => {
        console.log(err, "error");
        resolve({
          data: [],
        });
      });
  });

  export const  convertData = (response) => {
    var k = JSON.parse(JSON.stringify(response))
    var output = response.data.map((el) => {
      let element = {};
      
      el && el.forEach((fieldValue, fieldIndex) => {
        element[
          response.fields[
            response.fields.length <
            response.data[0].length
              ? fieldIndex - 1
              : fieldIndex
          ]
        ] = fieldValue;
      });
      return element;
    });
    console.log("aaaaaaaaaaaaaaaaaaa",{fields : k.fields , data : output})
  
    return {fields : k.fields , data : output};
  }