import React from "react";

import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props?.template?.[0]?.map(() => "1fr")?.join(" ") || ""};
  grid-template-areas: ${(props) =>
    props?.template?.map((t) => "'" + t.join(" ") + "'").join(" ")};
  column-gap: ${({ gap, colGap }) => colGap ?? gap ?? 20}px;
  row-gap: ${({ gap, rowGap }) => rowGap ?? gap ?? 20}px;
`;

export const GridItem = styled.div`
  grid-area: ${(props) => props.area};
`;

const GridWrapper = ({ template, items }) => (
  <Grid template={template}>
    {items &&
      items.map((item, i) => {
        const Component = item.component;
        return (
          <GridItem key={i} area={item.area}>
            <Component />
          </GridItem>
        );
      })}
  </Grid>
);

export default GridWrapper;
